const campaignColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'CampaignId',
    label: 'Campaign ID',
    show: false,
    headerTitle: 'Campaign ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'CampaignName',
    label: 'Campaign Name',
    show: true,
    headerTitle: 'Campaign Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: false,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PubfeedId',
    label: 'Feed ID',
    show: true,
    headerTitle: 'Feed ID',
    sortable: true,
    thStyle: 'width:80px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PubfeedName',
    label: 'Feed Name',
    show: false,
    headerTitle: 'Feed Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'CC',
    label: 'Country',
    show: false,
    headerTitle: 'Country',
    sortable: true,
    thStyle: 'width: 85px',
    class: 'text-center',
    summed: false,
  },
  {
    key: 'OsFamily',
    label: 'OS',
    show: false,
    headerTitle: 'OS',
    sortable: true,
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'OsMajor',
    label: 'OS Major',
    show: false,
    headerTitle: 'OS Major',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'OsMinor',
    label: 'OS Minor',
    show: false,
    headerTitle: 'OS Minor',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'BrowserFamily',
    label: 'Browser',
    show: false,
    headerTitle: 'Browser',
    sortable: true,
    thStyle: 'width:130px',
    summed: false,
  },
  {
    key: 'BrowserMajor',
    label: 'Browser Major',
    show: false,
    headerTitle: 'Browser Major',
    sortable: true,
    thStyle: 'width:120px',
    summed: false,
  },
  {
    key: 'Bids',
    label: 'Bids',
    show: true,
    headerTitle: 'Bids',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Impressions',
    label: 'Impressions',
    show: false,
    headerTitle: 'Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: true,
  },
  {
    key: 'Clicks',
    label: 'Clicks',
    show: true,
    headerTitle: 'Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'NetClicks',
    label: 'Net Clicks',
    show: true,
    headerTitle: 'Net Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'CampaignClicksDailyLimit',
    label: 'Cmp. Clicks Daily Limit',
    show: true,
    headerTitle: 'Cmp. Clicks Daily Limit',
    sortable: false,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:180px',
    summed: false,
  },
  {
    key: 'IpMismatch',
    label: 'IP Mismatch',
    show: false,
    headerTitle: 'IP Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'UaMismatch',
    label: 'UA Mismatch',
    show: false,
    headerTitle: 'UA Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'EmptyReferer',
    label: 'Empty Referer',
    show: false,
    headerTitle: 'Empty Referer',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'RefererMismatch',
    label: 'Referer Mismatch',
    show: false,
    headerTitle: 'Referer Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'RepeatedClick',
    label: 'Repeated Click',
    show: false,
    headerTitle: 'Repeated Click',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:130px',
    summed: true,
  },
  {
    key: 'Spent',
    label: 'Spent',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: true,
  },
]
export default campaignColumns
